import { client } from '../apollo-config.js'

import {
  getUserSession as getUserSessionGQL,
  getUserSessions as getUserSessionsGQL,
  postUserSession as postUserSessionGQL,
  updateUserSession as updateUserSessionGQL,
  getSessionActivity as getSessionActivityGQL,
  getSessionActivities as getSessionActivitiesGQL,
  postSessionActivity as postSessionActivityGQL,
  updateSessionActivity as updateSessionActivityGQL,
  getNpsEntry as getNpsEntryGQL,
  getNpsEntries as getNpsEntriesGQL,
  shouldGatherNpsEntry as shouldGatherNpsEntryGQL,
  getNpsScoreByCompany as getNpsScoreByCompanyGQL,
  getNpsScoreByCompanies as getNpsScoreByCompaniesGQL,
  postNpsEntry as postNpsEntryGQL
} from '../api-calls/sessionAnalytics'

import type { ISession } from '@bolster/types/sessionAnalytics/interfaces/session.ts'
import type { ISessionActivity } from '@bolster/types/sessionAnalytics/interfaces/session_activity.ts'
import type { ISessionFetchFilters } from '@bolster/types/sessionAnalytics/interfaces/session_fetch_filters.ts'
import type { ISessionActivityFetchFilters } from '@bolster/types/sessionAnalytics/interfaces/session_activity_fetch_filters.ts'
import type { INpsEntry } from '@bolster/types/sessionAnalytics/interfaces/nps_entry.ts'
import type { INpsEntryShouldGather } from '@bolster/types/sessionAnalytics/interfaces/nps_entry_should_gather.ts'
import type { INpsEntryFetchFilters } from '@bolster/types/sessionAnalytics/interfaces/nps_entry_fetch_filters.ts'

export async function getUserSession(session_id: string): Promise<ISession> {
  const appsyncClient = client()

  const { data } = await appsyncClient.query({
    query: getUserSessionGQL,
    variables: {
      session_id
    }
  })

  return data.getUserSession
}
export async function getUserSessions(
  filters: ISessionFetchFilters,
  limit: number,
  last_evaluated_key: string
): Promise<{
  items: ISession[]
  last_evaluated_key: string
}> {
  const appsyncClient = client()

  const { data } = await appsyncClient.query({
    query: getUserSessionsGQL,
    variables: {
      payload: {
        filters,
        limit,
        last_evaluated_key
      }
    }
  })

  return data.getUserSessions
}
export async function getSessionActivity(session_activity_id: string): Promise<ISessionActivity> {
  const appsyncClient = client()

  const { data } = await appsyncClient.query({
    query: getSessionActivityGQL,
    variables: {
      session_activity_id
    }
  })

  return data.getSessionActivity
}
export async function getSessionActivities(
  filters: ISessionActivityFetchFilters,
  limit: number,
  last_evaluated_key: string
): Promise<{ items: ISessionActivity[]; last_evaluated_key: string }> {
  const appsyncClient = client()

  const { data } = await appsyncClient.query({
    query: getSessionActivitiesGQL,
    variables: {
      payload: {
        filters,
        limit,
        last_evaluated_key
      }
    }
  })

  return data.getSessionActivities
}
export async function postUserSession(payload: Omit<ISession, 'session_id'>): Promise<ISession> {
  const appsyncClient = client()

  const { data } = await appsyncClient.mutate({
    mutation: postUserSessionGQL,
    variables: {
      payload
    }
  })
  return data.postUserSession
}
export async function updateUserSession(payload: ISession): Promise<ISession> {
  const appsyncClient = client()

  const { data } = await appsyncClient.mutate({
    mutation: updateUserSessionGQL,
    variables: {
      payload
    }
  })

  return data.updateUserSession
}
export async function postSessionActivity(
  payload: Omit<ISessionActivity, 'session_activity_id'>
): Promise<ISessionActivity> {
  const appsyncClient = client()

  const { data } = await appsyncClient.mutate({
    mutation: postSessionActivityGQL,
    variables: {
      payload
    }
  })

  return data.postSessionActivity
}
export async function updateSessionActivity(
  payload: Partial<ISessionActivity>
): Promise<ISessionActivity> {
  const appsyncClient = client()

  const { data } = await appsyncClient.mutate({
    mutation: updateSessionActivityGQL,
    variables: {
      payload
    }
  })

  return data.updateSessionActivity
}
export async function getNpsEntry(nps_entry_id: string): Promise<INpsEntry> {
  const appsyncClient = client()

  const { data } = await appsyncClient.query({
    query: getNpsEntryGQL,
    variables: {
      nps_entry_id
    }
  })

  return data.getNpsEntry
}
export async function getNpsEntries(
  filters: INpsEntryFetchFilters,
  limit: number,
  last_evaluated_key: string
): Promise<{ items: INpsEntry[]; last_evaluated_key: string }> {
  const appsyncClient = client()

  const { data } = await appsyncClient.query({
    query: getNpsEntriesGQL,
    variables: {
      payload: {
        filters,
        limit,
        last_evaluated_key
      }
    }
  })

  return data.getNpsEntries
}
export async function shouldGatherNpsEntry(payload: INpsEntryShouldGather): Promise<boolean> {
  const appsyncClient = client()

  const { data } = await appsyncClient.query({
    query: shouldGatherNpsEntryGQL,
    variables: {
      payload
    }
  })

  return data.shouldGatherNpsEntry
}
export async function getNpsScoreByCompany(company_id: string): Promise<number> {
  const appsyncClient = client()

  const { data } = await appsyncClient.query({
    query: getNpsScoreByCompanyGQL,
    variables: {
      company_id
    }
  })

  return data.getNpsScoreByCompany
}
export async function getNpsScoreByCompanies(
  company_ids: string[]
): Promise<{ company_id: string; score: number }> {
  const appsyncClient = client()

  const { data } = await appsyncClient.query({
    query: getNpsScoreByCompaniesGQL,
    variables: {
      company_ids
    }
  })

  return data.getNpsScoreByCompanies
}
export async function postNpsEntry(payload: Omit<INpsEntry, 'nps_entry_id'>): Promise<INpsEntry> {
  const appsyncClient = client()

  const { data } = await appsyncClient.mutate({
    mutation: postNpsEntryGQL,
    variables: {
      payload
    }
  })

  return data.postNpsEntry
}
