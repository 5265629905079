import { gql } from '@apollo/client/core'

export const getUserSession = gql`
  query getUserSession($session_id: ID!) {
    getUserSession(session_id: $session_id) {
      session_id
      time_start
      time_end
      user_id
      company_id
    }
  }
`

export const getSessionActivity = gql`
  query getSessionActivity($session_activity_id: ID!) {
    getSessionActivity(session_activity_id: $session_activity_id) {
      session_activity_id
      session_id
      session_activity_type
      session_activity_time_created
      page_name
      page_route
      page_time_start
      page_time_end
      click_element
      user_id
      company_id
    }
  }
`

export const getUserSessions = gql`
  query getUserSessions($payload: SessionAnalyticGetManyInput!) {
    getUserSessions(payload: $payload) {
      items {
        session_id
        time_start
        time_end
        user_id
        company_id
      }
      last_evaluated_key
    }
  }
`
export const getSessionActivities = gql`
  query getSessionActivities($payload: SessionActivityGetManyInput!) {
    getSessionActivities(payload: $payload) {
      items {
        session_activity_id
        session_id
        session_activity_type
        session_activity_time_created
        page_name
        page_route
        page_time_start
        page_time_end
        click_element
        user_id
        company_id
      }
      last_evaluated_key
    }
  }
`

export const postUserSession = gql`
  mutation postUserSession($payload: SessionAnalyticPostInput!) {
    postUserSession(payload: $payload) {
      session_id
      time_start
      time_end
      user_id
      company_id
    }
  }
`

export const updateUserSession = gql`
  mutation updateUserSession($payload: SessionAnalyticUpdateInput!) {
    updateUserSession(payload: $payload) {
      session_id
      time_start
      time_end
      user_id
      company_id
    }
  }
`

export const postSessionActivity = gql`
  mutation postSessionActivity($payload: SessionActivityPostInput!) {
    postSessionActivity(payload: $payload) {
      session_activity_id
      session_id
      session_activity_type
      session_activity_time_created
      page_name
      page_route
      page_time_start
      page_time_end
      click_element
      user_id
      company_id
    }
  }
`

export const updateSessionActivity = gql`
  mutation updateSessionActivity($payload: SessionActivityUpdateInput!) {
    updateSessionActivity(payload: $payload) {
      session_activity_id
      session_id
      session_activity_type
      session_activity_time_created
      page_name
      page_route
      page_time_start
      page_time_end
      click_element
    }
  }
`

export const getNpsEntry = gql`
  query getNpsEntry($nps_entry_id: ID!) {
    getNpsEntry(nps_entry_id: $nps_entry_id) {
      nps_entry_id
      company_id
      user_id
      nps_entry_type
      nps_entry_rating
      nps_entry_comment
      time_created
    }
  }
`

export const getNpsEntries = gql`
  query getNpsEntries($payload: NpsEntryGetManyInput!) {
    getNpsEntries(payload: $payload) {
      last_evaluated_key
      items {
        nps_entry_id
        company_id
        user_id
        nps_entry_type
        nps_entry_rating
        nps_entry_comment
        time_created
      }
    }
  }
`

export const shouldGatherNpsEntry = gql`
  query shouldGatherNpsEntry($payload: NpsEntryShouldGatherInput!) {
    shouldGatherNpsEntry(payload: $payload)
  }
`

export const getNpsScoreByCompany = gql`
  query getNpsScoreByCompany($company_id: ID!) {
    getNpsScoreByCompany(company_id: $company_id)
  }
`

export const getNpsScoreByCompanies = gql`
  query getNpsScoreByCompanies($company_ids: [ID]!) {
    getNpsScoreByCompanies(company_ids: $company_ids) {
      company_id
      score
    }
  }
`

export const postNpsEntry = gql`
  mutation postNpsEntry($payload: NpsEntryPostInput!) {
    postNpsEntry(payload: $payload) {
      nps_entry_id
      company_id
      user_id
      nps_entry_type
      nps_entry_rating
      nps_entry_comment
      time_created
    }
  }
`
